@font-face {
  font-family: 'Open Sans Regular' ;
  src: url('/fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: regular;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans Bold' ;
  src: url('/fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: bold;
}
@font-face {
  font-family:'Comfortaa' ;
  src: url('/fonts/Comfortaa-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family:'Comfortaa' ;
  src: url('/fonts/Comfortaa-VariableFont_wght.ttf') format('truetype');
  font-weight: bold;
  font-style: bold;
}
*{
  font-family: 'Comfortaa' sans-serif;
}
body {
  font-family: Comfortaa, sans-serif;
  background: #eff2f5;
}

h1,
h2,
h3 {
  font-weight: 600;
  color: white;
}

.ant-modal-header {
  border: none !important;
  padding-bottom: 0;
}
.ant-modal-title {
  font-size: 20px;
}
.ant-modal-content {
  width: auto !important;
  height: 100%;
}

.ant-message {
  top: initial;
  bottom: 0;
}

.ant-message-notice {
  text-align: right;
}

.ant-message-notice-content {
  background: rgba(255, 255, 255, 0.9) !important;
}

.ant-message .anticon {
  display: none;
}

.ant-comment-actions {
  margin-top: 2px !important;
}
.ant-comment-content-detail {
  font-size: 13px;
}
.comment-action {
  padding-left: 4px;
  cursor: 'auto';
}

.ant-comment-inner {
  padding: 0 !important;
}

.ant-form-item-explain {
  font-size: 12px;
  line-height: 1.2;
  min-height: initial;
}

.ant-input-textarea {
  position: relative;
}
.ant-form-item .ant-input-textarea-show-count::after {
  margin: 0;
  position: absolute;
  top: 0px;
  right: 2px;
  font-size: 8px;
}

.ant-collapse-arrow {
  position: absolute;
}

.ant-collapse-ghost {
  /* background-color: #eff2f5 !important; */
}

.ant-layout-sider {
  transition: width 0.3s ease-in-out, min-width 0.3s ease-in-out,
    flex 0.3s ease-in-out !important;
}
.errorImage span {
  height: 100vh !important;
 }

 /* .header-part{
    position: relative;
    color: white;
    border: 1px solid black;
 }
 .background-image{
  width: 100%;
  max-height: 575px;
 }

 .staff-pics{
  position: absolute;
  object-fit: cover;
  left: 0;
  bottom: 0;
  width: 50%;
  height: 120%;
  object-position: -50px 5px;
 }

 .background-text{
    position: absolute;
    top: 20%;
 } */

 .formulaire >.ant-form-item{
  margin-bottom: 5px !important;
  text-align: center;
  border-radius: 330px;
}
.ant-input,.ant-btn{
  /* width: 250px */
  border-radius: 6px !important;
  height: 28px;
}

.ant-btn{
  background-color: #6fba62 !important;
  border: #6fba62 !important;
  font-weight: bold !important;
}

.ant-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6fba62  !important;
  background: white !important;
  font-weight: bold !important;
}

.ant-layout {
  background: #ffffff !important;
}
.formulaire {
  width: 240px !important;
}
.form p {
  margin: 0 !important;
}

.ant-modal-mask{
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.ant-modal-content,.ant-modal-header,.ant-modal-body{
  background-color: #eeede8  !important;
  border-radius: 30px !important;
  color:#45413a !important;; 
  text-align: center;
  /* font-size: 14.5px !important; */

}
.ant-modal-body > img{
  margin-top: 40px !important;

}
.ant-modal-header{
  display: none;
}
.ant-form-item-explain, .ant-form-item-extra ,.ant-form-item-explain-error{
  color: white !important;
}